import React from 'react';
import Container from 'common/source/components/UI/Container';
import Image from 'reusecore/src/elements/Image';
import SEC_Mark from './img/sec-mark.png';
import TUV_Mark from './img/tuv-mark.png';
import IEC_Mark from './img/iec-mark.png';
import CE_Mark from './img/ce-mark.png';
import { PanelTableWrapper } from './solar.styles';

const PanelsTable = ({ panels }) => {
  return (
    <PanelTableWrapper>
      <Container>
        <div className="text-center">
          <h2>Resumen Modelos</h2>
        </div>
      </Container>
      <div className="panels-table">
        <table>
          <thead>
            <tr>
              <th className="g-width-100 g-pa-0 g-pb-10">Modelo</th>
              <th className="g-width-80 text-center">Celdas</th>
              <th className="g-width-80 text-center">Watts</th>
              <th className="g-width-120 text-center">Dimensiones</th>
              <th className="g-width-200 text-center">Certificados</th>
            </tr>
          </thead>
          <tbody>
            {panels.map((item, i) => {
              const { model, cells, watts, dim, certs } = item;
              const { SEC, TUV, IEC, CE } = certs;
              return (
                <tr key={`${model}-${i}`}>
                  <td className="text-center">{model}</td>
                  <td className="text-center">{cells}</td>
                  <td className="text-center">{watts}</td>
                  <td className="text-center">{dim}</td>
                  <td className="text-center">
                    <div className="cert-col">
                      {SEC && (
                        <span>
                          <Image
                            id={`${model}-sec`}
                            className="cert-panel-img"
                            src={SEC_Mark}
                            alt="UpSolar sec certificado"
                          />
                        </span>
                      )}
                      {TUV && (
                        <span>
                          <Image
                            id={`${model}-tuv`}
                            className="cert-panel-img"
                            src={TUV_Mark}
                            alt="UpSolar tuv certificado"
                          />
                        </span>
                      )}
                      {IEC && (
                        <span>
                          <Image
                            id={`${model}-iec`}
                            className="cert-panel-img"
                            src={IEC_Mark}
                            alt="UpSolar IEC certificado"
                          />
                        </span>
                      )}
                      {CE && (
                        <span>
                          <Image
                            id={`${model}-ce`}
                            className="cert-panel-img"
                            src={CE_Mark}
                            alt="UpSolar ce certificado"
                          />
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </PanelTableWrapper>
  );
};

export default PanelsTable;

import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
// import Container from 'common/source/components/UI/Container';

import Navbar from 'common/source/containers/SolarNavbar';
import Footer from 'common/source/containers/Footer';
// import RegionBanner from 'common/source/containers/RegionBanner';
import CTABanner from 'common/source/containers/CTABanner';

import SolarPanelView from '../../components/Catalog/Solar/SolarPanelView';
import SEO from '../../components/SEO';

class SolarPanelsTemplate extends Component {
  render() {
    const { pageContext, data } = this.props;
    const { slug } = pageContext;
    const post = data.panels;
    const { frontmatter } = post;
    if (!frontmatter.id) {
      frontmatter.id = slug;
    }
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={post} postSEO prefix="product" />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar />
            <SolarPanelView product={post} />
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default SolarPanelsTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query SolarPanelsBySlug($slug: String!) {
    panels: markdownRemark(frontmatter: { slug: { eq: $slug }, type: { eq: "SOLAR-PANEL" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        title
        cover
        date
        price
        price_ref
        type
        panels {
          model
          cells
          watts
          dim
          certs {
            SEC
            TUV
            IEC
            CE
          }
        }
      }
    }
  }
`;

import React from 'react';
import { Link } from 'gatsby';
import Container from 'common/source/components/UI/Container';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Button from 'reusecore/src/elements/Button';

import { PanelHeroWrapper } from './solar.styles';

const PanelHero = ({ product }) => {
  const {
    frontmatter: { title, cover }
  } = product;

  return (
    <PanelHeroWrapper>
      <Container>
        <Box className="row centered">
          <Box className="col info">
            <div className="product-line-title">
              <span className="heading">Energía Solar</span>
              <h3>{title}</h3>
            </div>
            <div
              className="product-line-info"
              dangerouslySetInnerHTML={{ __html: product.html }}
            />
          </Box>
          <Box className="col image">
            <Image
              className="img-fluid img-no-hover"
              style={{ width: '50%', padding: 0, margin: 0 }}
              src={cover}
              alt={`UpSolar ${title}`}
            />
          </Box>
        </Box>
        <article className="row flex-md-row align-items-center text-center" />
      </Container>
      <Container className="center-cta">
        <div className="cta-btn">
          <Link to={`/contacto?product=${product.frontmatter.slug}`}>
            <Button title="Contactanos" />
          </Link>
        </div>
        <div className="cta-details">
          Para más información, también puede revisar
          <br />
          <Link to="/preguntas-frecuentes">preguntas frecuentes</Link>
        </div>
      </Container>
    </PanelHeroWrapper>
  );
};

export default PanelHero;

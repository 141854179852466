import React from 'react';

import PanelHero from './PanelHero';
import PanelsTable from './PanelsTable';
import { PanelViewWrapper } from './solar.styles';

const SolarPanelView = ({ product }) => {
  return (
    <PanelViewWrapper>
      <PanelHero product={product} />
      <PanelsTable panels={product.frontmatter.panels} />
    </PanelViewWrapper>
  );
};

export default SolarPanelView;
